<template>
  <footer class="footer">
    <div class="footer__wrapper">
      <div class="footer__container _container">
        <div class="footer__body">
          <div class="footer__rights">
            Сopyright © 2023 - 2025 RunLiq. All rights reserved.
            <div class="footer__links">
              <router-link :to="'/privacy-policy'">Privacy Policy</router-link>
              | <router-link :to="'/terms-of-service'">Terms Of Service</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
</script>

<style lang="scss" scoped>
.footer {
  &__body {
    @include adaptiv-padding(12, 8, 0, 0, 1);
    @include adaptiv-value(margin-top, 30, 20, 1);
  }

  &__rights {
    font-family: $fontGeometria;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #8c8c8c;
    @include adaptiv-font(12, 11);
    row-gap: 6px;
  }

  &__links {
    a {
      color: #8c8c8c;

      @media (any-hover:hover) {
        cursor: pointer;
        transition: all 0.3s ease 0s;

        &:hover {
          color: $colorBlue;
        }
      }
    }
  }
}
</style>
